import { http } from "../utils/http";
import { API_ENDPOINTS } from "../config";
import { store } from "../State/store/configureStore";
import { isStaff, ROLE_PATIENT } from "../security/roles";

class ConfigurationService {
  
  static getScreenConfig(patientTypeId, screenType) {
    return http.request(API_ENDPOINTS.getScreenConfig(patientTypeId, screenType));
  }

  static getScreenConfigStaff(patientTypeId, screenType) {
    return http.request(API_ENDPOINTS.getScreenConfigStaff(patientTypeId, screenType));
  }

  static addInfoPanel(patientTypeId, data, isImport) {
    return http.request(
      API_ENDPOINTS.addInfoPanel(patientTypeId, isImport),
      "post",
      data
    );
  }

  static fetchStaffInfoPanel() {
    return http.request(API_ENDPOINTS.staffInfoPanelView);
  }

  static getStaffInfoPanel(isExport) {
    return http.request(API_ENDPOINTS.staffInfoPanel(isExport));
  }

  static addStaffInfoPanel(data, isImport) {
    return http.request(
      API_ENDPOINTS.staffInfoPanel(false, isImport),
      "post",
      data
    );
  }

  static createPredefinedItem(patientTypeId, data) {
    return http.request(
      API_ENDPOINTS.predefinedItems(patientTypeId),
      "post",
      data
    );
  }

  static loadPredefinedItems(patientTypeId) {
    return http
      .request(
        API_ENDPOINTS.predefinedItems(patientTypeId) + `?page=0&size=200`
      )
      .then(data => {
        if (data && data._embedded) {
          return data._embedded.predefinedItems || [];
        }
        return [];
      });
  }

  static loadConsentDocuments(patientTypeId, allStatus) {
    let api = API_ENDPOINTS.consentDocuments() + "?page=0&size=500";
    if (patientTypeId) {
      api += `&pTypeId=${patientTypeId}`;
    }
    if (allStatus) {
      api += `&allStatus=true`;
    }

    return http.request(api).then(data => {
      if (data && data._embedded) {
        return data._embedded.consentDocuments || [];
      }
      return [];
    });
  }

  static createConsentDocument(data) {
    return http.request(API_ENDPOINTS.consentDocuments(), "post", data);
  }

  static loadConsentDocument(documentId) {
    return http.request(API_ENDPOINTS.consentDocuments() + "/" + documentId);
  }

  static editConsentDocument(documentId, data) {
    return http.request(
      API_ENDPOINTS.consentDocuments() + "/" + documentId,
      "patch",
      data
    );
  }

  static deleteConsentDocument(documentId) {
    return http.request(
      API_ENDPOINTS.consentDocuments() + "/" + documentId,
      "delete"
    );
  }

  static loadUIAttributesForFieldsConfig = () => {
    return http
      .request(API_ENDPOINTS.uiAttributesForFields + `?page=0&size=1000`)
      .then((data) => {
        return data && data._embedded ? data._embedded.uIFields || [] : [];
      });
  };

  static loadFieldsConfig = () => {
    return http.request(API_ENDPOINTS.fieldsConfig).then(data => {
      return data ? data.artifactUIFields || [] : [];
    });
  };

  static saveFieldsConfig = data => {
    return http.request(API_ENDPOINTS.fieldsConfig, "post", data);
  };

  static fetchAppointmentAttributes(scope) {
    let api = API_ENDPOINTS.appointmentAttributes + "?page=0&size=500";

    if (scope) {
      api += "&scope=" + scope;
    }

    return http.request(api).then(data => {
      return data && data._embedded
        ? data._embedded.appointmentAttributes || []
        : [];
    });
  }

  static getClinicColumns() {
    let api = API_ENDPOINTS.clinicColumns;

    return http.request(api).then(data => {
      return data && data.clinicListingColumnsToDisplay || [];
    });
  }

  static postClinicColumns(clinicListingColumnsToDisplay) {
    let api = API_ENDPOINTS.clinicColumns;

    return http.request(api, "post", { clinicListingColumnsToDisplay })
    .then(data => {
      return data && data.clinicListingColumnsToDisplay || [];
    });
  }

  static addAppointmentAttribute(data) {
    return http
      .request(API_ENDPOINTS.appointmentAttributes, "post", data)
      .then(data => {
        return data && data._embedded
          ? data._embedded.appointmentAttributes || []
          : [];
      });
  }

  static cloneConsentDocument(consentId, data) {
    return http.request(API_ENDPOINTS.consentClone(consentId), "post", data);
  }

  static createAction(actionData) {
    return http.request(API_ENDPOINTS.actions(), "post", actionData);
  }

  static getActions(patientTypeId) {
    return http.request(API_ENDPOINTS.actions(patientTypeId)).then(data => {
      return data && data._embedded ? data._embedded.embeddedActions || [] : [];
    });
  }

  static editAction(actionId, actionData) {
    return http.request(API_ENDPOINTS.action(actionId), "patch", actionData);
  }

  static deleteAction(actionId) {
    return http.request(API_ENDPOINTS.action(actionId), "delete");
  }

  static fetchReportIntegrations() {
    return http.request(API_ENDPOINTS.reportIntegrations());
  }

  static getComorbidityTemplates(patientTypeId) {
    return http.request(API_ENDPOINTS.getComorbidityTemplates(patientTypeId));
  }

  static postComorbidityTemplate(data) {
    return http.request(API_ENDPOINTS.postComorbidityTemplate(), "post", data);
  }

  static deleteComorbidityTemplate(comorbidityTemplateId) {
    return http.request(API_ENDPOINTS.deleteComorbidityTemplate(comorbidityTemplateId), "delete");
  }

  static addEpisodeOfCareMappedData(patientTypeId, data) {
    return http.request(API_ENDPOINTS.episodeOfCareTemplates(patientTypeId), "post", data)
  }
  
  static loadEpisodeOfCareMappedData(patientTypeId) {
    return http.request(API_ENDPOINTS.episodeOfCareTemplates(patientTypeId))
  }
}
const configurationService = ConfigurationService;

export default configurationService;
